
import React, { useState } from "react";
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';

const TimePicker_OTPCRM = () => {
    return (
        <TimePicker
            defaultValue={moment()}
            showSecond={false}
            minuteStep={30}
            hourStep={1}
            inputPlaceholder="Select a Time"
            className="TimePicker_OTPCRM"
        />
    )
}

export default TimePicker_OTPCRM