import React, { useState } from "react";
import firebase from "./API Data/firebase";
import Calendar from "./Components/Calendar";
import "./App.css";
import TimePicker_OTPCRM from "./Components/TimePicker";
import axios from "axios";
import DemoForm from "./Components/demoForm";

// import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';


class App extends React.Component {
  

  constructor(props) {

    super(props);

    this.formValues = {
      email: "",
      firstName: "",
      lastName: "",
      facilityName: "",
      businessStage: "",
      noOfUnits: "",
      currentSoftwareVendor: ""
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value

    })
  }
  configureCaptcha = () => {
    // const auth = getAuth();
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.onSignInSubmit();
        alert("Recaptcha Verified")
      },
      // defaultCountry: "IN"
    });
  }
  onSignInSubmit = (e) => {
    e.preventDefault()
    this.configureCaptcha()
    const phoneNumber = "+91" + this.state.mobile
    const appVerifier = window.recaptchaVerifier;

    // const auth = getAuth();
    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        alert("OTP has been sent..")
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        // ...
        alert("SMS not sent..")

      });
  }

  sixStorageGenerateToken() {

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let data = {
      refresh_token: '1000.65c40d8d8c9d052659df7c514d69c69c.7fc97e889aa08e53bb3735ba4938481a',
      client_id: '1000.L9KXLPJHXGFFKHJJVLBIBG5ZJVZJ1S',
      client_secret: '9c8441d12d542516e8bf8df2a0f4dec16844122580',
      grant_type: 'refresh_token'
    }

    axios.post('https://accounts.zoho.com/oauth/v2/token', data, config).then((data) => {
      console.log(data);
      this.sixStorageSubmitFormDetails(data.access_token);

    }).catch((err) => {
      console.log(err);
    })
  }

  sixStorageSubmitFormDetails(access_token) {

    console.log(access_token);
    let config1 = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Zoho-oauthtoken" + access_token
      },
    };

    // mobile: "",
    // email: "",
    // firstName: "",
    // lastName: "",
    // facilityName: "",
    // businessStage: "",
    // noOfUnits: "",
    // currentSoftwareVendor: ""
    // })

    console.log(this.formValues);

    axios.post('https://www.zohoapis.com/crm/v2/Leads', '', config1).then((name) => {
      console.log(name)
      this.sixStorageGenerateToken()

    }).catch((err) => {
      console.log(err);
    })

  }

  onSubmitOTP = (e) => {
    e.preventDefault()
    const code = this.state.otp
    window.confirmationResult.confirm(code).then((result) => {
      // User signed in successfully.
      const user = result.user;
      // console.log(JSON.stringify(user))
      alert("User is Verified")
      // ...
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      // ...


      alert("Please enter correct otp..")
    });
  }

  // submitHandler = (e) => {
  //   e.preventDefault();
  //   const email = e.target.title.value;
  //   const data = { email };
  //   axios
  //     .post("https://www.zohoapis.com/crm/v2/Leads", data)
  //     .then((response) => {
  //       console.log(response);
  //       e.target.reset();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })

  // };

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     error: null,
  //     isLoaded: false,
  //     Leads: []
  //   };
  // }

  // componentDidMount() {
  //   fetch("https://www.zohoapis.com/crm/v2/Leads")
  //     .then(res => res.json())
  //     .then(
  //       (result) => {
  //         this.setState({
  //           isLoaded: true,
  //           Leads: result.Leads
  //         });
  //       },
  //       // Note: it's important to handle errors here
  //       // instead of a catch() block so that we don't swallow
  //       // exceptions from actual bugs in components.
  //       (error) => {
  //         this.setState({
  //           isLoaded: true,
  //           error
  //         });
  //       }
  //     )
  // }


  changeHander_CRM = (e) => {
    this.formValues({
      [e.target.name]: e.target.value
    })
  }

  submitHandler_CRM = (e) => {
    e.preventDefault()
    this.sixStorageSubmitFormDetails()
    // axios
    //   .post('https://www.zohoapis.com/crm/v2/Leads', this.state)
    //   .then(response => {
    //     console.log(response)
    //   })
  }


  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     postId: null
  //   };
  // }

  // componentDidMount() {
  //   // Simple POST request with a JSON body using fetch
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //     body: JSON.stringify({ title: 'React POST Request Example' })
  //   };
  //   fetch('https://www.zohoapis.com/crm/v2/Leads', requestOptions)
  //     .then(response => response.json())
  //     .then(data => this.setState({ postId: data.id }));
  // }


  render() {

    // const { fName, lName, facility_Name, business_Stage, no_of_Units, current_SW_Ventor } = this.state
    // // const { userId, title, body } = this.state
    // const { postId } = this.state;

    return (
      <div className=" container mx-auto my-16">
        {/* <div>
          postId : {postId}
        </div> */}

        <div className=" max-w-sm mx-auto rounded-2xl border-2 border-green-600 border-solid my-5">
          <div className=" py-5">
            <h2 className=" text-2xl text-center font-bold">
              <span>Signup</span>
              <span> - </span>
              <span>Schedule</span>
              <span> - </span>
              <span>Demo</span>
            </h2>
            <p className=" flex flex-row items-center justify-evenly w-5/12 mx-auto">
              <span className=" w-4 h-4 border-2 rounded-full border-green-400 bg-orange-300"></span>
              <svg class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
              <span className=" w-4 h-4 border-2 rounded-full border-green-400  bg-green-800"></span>
              <svg class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
              <span className=" w-4 h-4 border-2 rounded-full border-green-400 bg-green-800"></span>
            </p>
          </div>
          <div className=" p-5 max-w-2xl w-full mx-auto text-center inline-grid items-start justify-center">
            <form onSubmit={this.onSignInSubmit} className=" mx-auto ">
              <div id="sign-in-button"></div>
              <div className=" inline-grid items-center justify-center">
                <h3 className=" text-2xl font-medium">
                  Enter Mobile Number
                </h3>
                <div className=" my-2 inline-grid">
                  {/* <IntlTelInput                    
                    containerClassName="intl-tel-input"
                    inputClassName="form-control tel border-2 border-gray-800 rounded-md px-1.5"
                    onChange={this.handleChange}
                    inputName="mobile"
                    inputValueName ="Test"
                  /> */}

                  <input
                    className=" border-2 border-gray-800 rounded-md px-1.5"
                    type="tel"
                    name="mobile"
                    placeholder="1234567890"
                    // value={mobile}
                    onChange={this.handleChange}
                    min="3"
                    max="15"
                    required />
                </div>
                <div>
                  <button
                    type="submit"
                    className=" float-right text-base text-blue-800 font-bold">
                    Send OTP
                  </button>
                </div>
              </div>
            </form>
            <form onSubmit={this.onSubmitOTP} className=" mx-auto ">
              <div className=" inline-grid items-start justify-center">
                <h3 className=" text-2xl font-medium">
                  Verify Mobile Number
                </h3>
                <div className=" my-2 inline-grid">
                  <input
                    className=" border-2 border-gray-800 rounded-md px-1.5"
                    type="tel"
                    name="otp"
                    placeholder="123456"
                    onChange={this.handleChange}
                    required />
                </div>
                <div className=" my-5 ">
                  <button
                    type="submit"
                    className=" text-lg bg-green-400 rounded-lg py-3 px-5 font-medium text-white flex flex-row items-center justify-center ">
                    Schedule Call Back <svg class="h-8 w-8 ml-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className=" max-w-sm mx-auto rounded-2xl border-2 border-green-600 border-solid my-5">
          <div className=" py-5">
            <h2 className=" text-2xl text-center font-bold">
              <span>Signup</span>
              <span> - </span>
              <span>Schedule</span>
              <span> - </span>
              <span>Demo</span>
            </h2>
            <p className=" flex flex-row items-center justify-evenly w-5/12 mx-auto">
              <span className=" w-4 h-4 border-2 rounded-full border-green-400  bg-green-800"></span>
              <svg class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
              <span className=" w-4 h-4 border-2 rounded-full border-green-400 bg-orange-300"></span>
              <svg class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
              <span className=" w-4 h-4 border-2 rounded-full border-green-400 bg-green-800"></span>
            </p>
          </div>
          <div className=" p-5 max-w-2xl w-full mx-auto text-center inline-grid items-start justify-center">
            <form className=" mx-auto " onSubmit={this.submitHandler}>
              <div className=" my-1 inline-grid items-center justify-center">
                <h3 className=" text-2xl font-medium">
                  Preferred Date for Call Back
                </h3>
                <div className=" my-2">
                  <Calendar />
                </div>
              </div>
              <div className=" my-1 inline-grid items-center justify-center">
                <h3 className=" text-2xl font-medium">
                  Preferred Time for Call Back
                </h3>
                <div className=" my-2">
                  <TimePicker_OTPCRM />
                </div>
              </div>
              <div className=" my-1 inline-grid items-center justify-center">
                <h3 className=" text-2xl font-medium">
                  Email
                </h3>
                <div className=" my-2 inline-grid w-72 h-9 mx-auto max-w-xs ">
                  <input
                    name="email"
                    type="email"
                    id="email"
                    // value={email}
                    onChange={this.changeHander_CRM}
                    placeholder="Please Enter Email ID"
                    className=" border-2 border-gray-800 rounded-md px-2  w-full"
                  />
                </div>
              </div>
              <div className=" inline-grid items-start justify-center">
                <div className=" my-5 ">
                  <button
                    type="submit"
                    className=" text-lg bg-green-400 rounded-lg py-3 px-5 font-medium text-white flex flex-row items-center justify-center w-72 mx-auto">
                    Schedule Call Back <svg class="h-8 w-8 ml-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </button>
                </div>
              </div>
            </form>
            <form onSubmit={this.onSubmitOTP} className=" mx-auto ">

            </form>
          </div>
        </div>

        <div>
          < DemoForm />
        </div>

        {/* <div className=" max-w-sm mx-auto rounded-2xl border-2 border-green-600 border-solid my-5">
          <div className=" py-5">
            <h2 className=" text-2xl text-center font-bold">
              <span>Signup</span>
              <span> - </span>
              <span>Schedule</span>
              <span> - </span>
              <span>Demo</span>
            </h2>
            <p className=" flex flex-row items-center justify-evenly w-5/12 mx-auto">
              <span className=" w-4 h-4 border-2 rounded-full border-green-400  bg-green-800"></span>
              <svg class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
              <span className=" w-4 h-4 border-2 rounded-full border-green-400 bg-green-800"></span>
              <svg class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
              <span className=" w-4 h-4 border-2 rounded-full border-green-400 bg-orange-300"></span>
            </p>
          </div>
          <div className=" p-5 max-w-2xl w-full mx-auto text-center inline-grid items-start justify-center">
            <form className="mx-auto" onSubmit={this.submitHandler_CRM}>
              <div className=" my-1 inline-grid items-center justify-center">
                <input
                  type="text"
                  className="ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                  id="fName"
                  // value={firstName}
                  name="firstName"
                  placeholder="First Name*"
                  onChange={this.changeHander_CRM}
                  required
                />
              </div>
              <div className=" my-1 inline-grid items-center justify-center">
                <input
                  type="text"
                  className="ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                  id="lName"
                  // value={lastName}
                  name="lastName"
                  placeholder="Last Name"
                  onChange={this.changeHander_CRM}

                />
              </div>
              <div className=" my-1 inline-grid items-center justify-center">
                <input
                  type="text"
                  className="ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                  id="facility_Name"
                  // value={facilityName}
                  name="facilityName"
                  placeholder="Facility Name*"
                  onChange={this.changeHander_CRM}
                />
              </div>
              <div className=" my-1 inline-grid items-center justify-center">
                <input
                  type="text"
                  className=" ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                  id="business_Stage"
                  // value={businessStage}
                  name="businessStage"
                  placeholder="Business Stage*"
                  onChange={this.changeHander_CRM}

                />
              </div>
              <div className=" my-1 inline-grid items-center justify-center">
                <input
                  type="number"
                  className=" ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                  id="no_of_Units"
                  name="noOfUnits"
                  // value={noOfUnits}
                  placeholder="No of Units"
                  onChange={this.changeHander_CRM}
                />
              </div>
              <div className=" my-1 inline-grid items-center justify-center">
                <input
                  type="text"
                  className=" ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                  id="current_Software_Ventor"
                  name="currentSoftwareVendor"
                  // value={currentSoftwareVendor}
                  placeholder="Current Software Vendor"
                  onChange={this.changeHander_CRM}

                />
              </div>
              <div className=" inline-grid items-start justify-center">
                <div className=" my-5 ">
                  <button
                    type="submit"
                    className=" text-lg bg-green-400 rounded-lg py-3 px-5 font-medium text-white flex flex-row items-center justify-center w-72 mx-auto ">
                    Schedule Call Back <svg class="h-8 w-8 ml-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div> */}
      </div>
    )

  }
}



export default App
