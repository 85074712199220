import firebase from 'firebase/app';
import 'firebase/auth';

var firebaseConfig = {
  apiKey: "AIzaSyCZVzthiCFlHrfDpdkw9Mke1JgNk_HgyfU",
  authDomain: "otp-leads-crm.firebaseapp.com",
  projectId: "otp-leads-crm",
  storageBucket: "otp-leads-crm.appspot.com",
  messagingSenderId: "420005860652",
  appId: "1:420005860652:web:c8db8c81eeb92e0b9d8889"
};


firebase.initializeApp(firebaseConfig);

export default firebase

//   const app = initializeApp(firebaseConfig);