import React, { useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";

const Calendar = () => {
  const [selectedDay, setSelectedDay] = useState(null);
  return (
    <DatePicker
      value={selectedDay}
      onChange={setSelectedDay}
      inputPlaceholder="Select a Date"
      inputClassName=" w-full text-black border-2 border-gray-800 rounded-md placeholder:text-black Calendar_OTPCRM"
      inputStyleName=" background: #000;"
      shouldHighlightWeekends
      
    />
  );
};

export default Calendar;