import React, { useState } from 'react';
import axios from 'axios';

const DemoForm = () => {
    //const url = "https://www.zohoapis.com/crm/v2/Leads"
    // const url ="https://localhost:44379/api/MainMenu/Create"
    const [formValues, setFormValues] = useState({
        mobile: "",
        email: "",
        firstName: "",
        lastName: "",
        facilityName: "",
        businessStage: "",
        noOfUnits: "",
        currentSoftwareVendor: ""
    })
    

    function sixStorageGenerateToken() {

        let config = {
            headers: {
                // "Origin" : "*",
                "Content-Type": "application/json",

            },
        };

        console.log(formValues);

        let postData = {
            refresh_token: '1000.65c40d8d8c9d052659df7c514d69c69c.7fc97e889aa08e53bb3735ba4938481a',
            client_id: '1000.L9KXLPJHXGFFKHJJVLBIBG5ZJVZJ1S',
            client_secret: '9c8441d12d542516e8bf8df2a0f4dec16844122580',
            grant_type: 'refresh_token'
        }

        axios.post('https://accounts.zoho.com/oauth/v2/token', postData, config).then((data) => {
            console.log(data);
            sixStorageSubmitFormDetails(data);

        }).catch((err) => {
            console.log(err);
        })
    }

    function sixStorageSubmitFormDetails(access_token) {

        console.log(access_token);
        let config1 = {
            headers: {
                // "Origin" : "*",
                "Content-Type": "application/json",
                "Authorization": "Zoho-oauthtoken" + access_token,

            },
        };

        // mobile: "",
        // email: "",
        // firstName: "",
        // lastName: "",
        // facilityName: "",
        // businessStage: "",
        // noOfUnits: "",
        // currentSoftwareVendor: ""
        // })

        console.log(formValues);

        axios.post('https://www.zohoapis.com/crm/v2/Leads', '', config1).then((name) => {
            console.log(name)
            this.sixStorageGenerateToken()

        }).catch((err) => {
            console.log(err);
        })

    }

    function handle_CRM(e) {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
    function Submit_CRM(e) {
        e.preventDefault();
        sixStorageGenerateToken();
        // axios.post(url, {
        //     fName: data.fName,
        //     lName: data.lName,
        //     facility_Name: data.facility_Name,
        //     business_Stage: data.business_Stage,
        //     no_of_Units: parseInt(data.no_of_Units),
        //     current_SW_Ventor: data.current_SW_Ventor

        // })
        //     .then(res => {
        //         console.log(res.data)
        //     })
    }
    return (
        <div>
            <div className=" max-w-sm mx-auto rounded-2xl border-2 border-green-600 border-solid my-5">
                <div className=" py-5">
                    <h2 className=" text-2xl text-center font-bold">
                        <span>Signup</span>
                        <span> - </span>
                        <span>Schedule</span>
                        <span> - </span>
                        <span>Demo</span>
                    </h2>
                    <p className=" flex flex-row items-center justify-evenly w-5/12 mx-auto">
                        <span className=" w-4 h-4 border-2 rounded-full border-green-400  bg-green-800"></span>
                        <svg class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                        <span className=" w-4 h-4 border-2 rounded-full border-green-400 bg-green-800"></span>
                        <svg class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                        <span className=" w-4 h-4 border-2 rounded-full border-green-400 bg-orange-300"></span>
                    </p>
                </div>
                <div className=" p-5 max-w-2xl w-full mx-auto text-center inline-grid items-start justify-center">
                    <form className="mx-auto" onSubmit={(e) => Submit_CRM(e)}>
                        <div className=" my-1 inline-grid items-center justify-center">
                            <input
                                type="text"
                                className="ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                                id="fName"
                                //value={formValues.firstName}
                                name="firstName"
                                placeholder="First Name*"
                                onChange={(e) => handle_CRM(e)}
                                required
                            />
                        </div>
                        <div className=" my-1 inline-grid items-center justify-center">
                            <input
                                type="text"
                                className="ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                                id="lName"
                                //value={formValues.lastName}
                                name="lastName"
                                placeholder="Last Name"
                                onChange={(e) => handle_CRM(e)}
                                required
                            />
                        </div>
                        <div className=" my-1 inline-grid items-center justify-center">
                            <input
                                type="text"
                                className="ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                                id="facility_Name"
                                //value={formValues.facilityName}
                                name="facilityName"
                                placeholder="Facility Name*"
                                onChange={(e) => handle_CRM(e)}
                                required
                            />
                        </div>
                        <div className=" my-1 inline-grid items-center justify-center">
                            <input
                                type="text"
                                className=" ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                                id="business_Stage"
                                //value={formValues.businessStage}
                                name="businessStage"
                                placeholder="Business Stage*"
                                onChange={(e) => handle_CRM(e)}

                            />
                        </div>
                        <div className=" my-1 inline-grid items-center justify-center">
                            <input
                                type="number"
                                className=" ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                                id="no_of_Units"
                                name="noOfUnits"
                                //value={formValues.noOfUnits}
                                placeholder="No of Units"
                                onChange={(e) => handle_CRM(e)}
                                required
                            />
                        </div>
                        <div className=" my-1 inline-grid items-center justify-center">
                            <input
                                type="text"
                                className=" ml-2 border-2 border-gray-300 rounded-md px-2 h-9 flex-grow text-lg w-72"
                                id="current_SW_Ventor"
                                name="currentSoftwareVendor"
                                // value={formValues.currentSoftwareVendor}
                                placeholder="Current S/W Vendor"
                                onChange={(e) => handle_CRM(e)}

                            />
                        </div>
                        <div className=" inline-grid items-start justify-center">
                            <div className=" my-5 ">
                                <button
                                    type="submit"
                                    className=" text-lg bg-green-400 rounded-lg py-3 px-5 font-medium text-white flex flex-row items-center justify-center w-72 mx-auto ">
                                    Schedule Call Back <svg class="h-8 w-8 ml-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DemoForm